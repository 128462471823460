
import {defineComponent, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import {TypeResources} from "@/models";

export default defineComponent({
	emits: ['next-page', 'open-details', 'action'],
	props: {
		typeResources: {
			type: Array as PropType<TypeResources.TypeResource[]>,
			required: true
		},
		icon: {
			type: String,
			default: 'hide'
		}
	},
	setup() {
		const { t } = useI18n();
		return { t }
	}
})
