import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/images/no-image.png'


const _hoisted_1 = { class: "list-map" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "list-map-item-avatar" }
const _hoisted_4 = { class: "maps-list-item-img" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  src: _imports_0
}
const _hoisted_7 = { class: "list-map-item-content" }
const _hoisted_8 = { class: "text-body-2" }
const _hoisted_9 = { class: "list-map-item-action ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_tooltip = _resolveComponent("app-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeResources, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "list-map-item px-3",
        key: i,
        onClick: ($event: any) => (_ctx.$emit('next-page', item.id))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            ((item.avatar ? item.avatar : "").indexOf("base64") > 0)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: item.avatar
                }, null, 8, _hoisted_5))
              : (_openBlock(), _createElementBlock("img", _hoisted_6))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(item.name), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_app_tooltip, { placement: "bottom-end" }, {
            content: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("button.details")) + " " + _toDisplayString(_ctx.t("dictionary.typeResource")), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_app_button, {
                icon: "",
                onClick: _withModifiers(($event: any) => (_ctx.$emit("open-details", item)), ["stop"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_icon, {
                    icon: "info",
                    size: 16
                  })
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_app_tooltip, { placement: "bottom-end" }, {
            content: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("button.hide")) + " " + _toDisplayString(_ctx.t("dictionary.typeResource")), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_app_button, {
                icon: "",
                onClick: _withModifiers(($event: any) => (_ctx.$emit("action", item.id)), ["stop"]),
                class: "ml-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_icon, {
                    icon: _ctx.icon,
                    size: 16
                  }, null, 8, ["icon"])
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024)
        ])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}