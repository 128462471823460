import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row ma-n2"
}
const _hoisted_2 = {
  key: 0,
  class: "col-12 pa-2"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "text-body-2" }
const _hoisted_5 = {
  key: 2,
  class: "d-flex justify-center my-4"
}
const _hoisted_6 = {
  key: 0,
  class: "row ma-n2"
}
const _hoisted_7 = {
  key: 0,
  class: "col-12 pa-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_spinner = _resolveComponent("app-spinner")!
  const _component_ListMap = _resolveComponent("ListMap")!
  const _component_GridMap = _resolveComponent("GridMap")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_spinner, {
      loading: _ctx.loading,
      wide: ""
    }, null, 8, ["loading"]),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.showItems.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.mapView === "list")
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_ListMap, {
                        typeResources: _ctx.showItems,
                        onNextPage: _ctx.nextPage,
                        onOpenDetails: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit("open-details", $event.id, "typeResource"))),
                        onAction: _ctx.actionHideTR,
                        icon: "hide"
                      }, null, 8, ["typeResources", "onNextPage", "onAction"])
                    ]))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.showItems, (item, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["pa-2", _ctx.isTablet ? (!(_ctx.store.state.globals.detailsTypeResource || _ctx.store.state.globals.detailsResource) && _ctx.isLaptop ? "col-2" : "col-3") : "col-6"]),
                        key: i
                      }, [
                        _createVNode(_component_GridMap, {
                          item: item,
                          onNextPage: _ctx.nextPage,
                          onOpenDetails: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit("open-details", $event.id, "typeResource"))),
                          onAction: _ctx.actionHideTR,
                          icon: "hide"
                        }, null, 8, ["item", "onNextPage", "onAction"])
                      ], 2))
                    }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t("form.notify.noResultsFound")), 1)
              ])),
          (_ctx.idsTR.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_app_button, {
                  large: "",
                  outlined: "",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isShowHideTR = !_ctx.isShowHideTR)),
                  style: {"width":"100%","max-width":"320px"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isShowHideTR ? _ctx.t("button.hide") : _ctx.t("button.show")) + " " + _toDisplayString(_ctx.t("dictionary.typeResources")), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.isShowHideTR)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                (_ctx.hideItems.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (_ctx.mapView === "list")
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_ListMap, {
                              typeResources: _ctx.hideItems,
                              onNextPage: _ctx.nextPage,
                              onOpenDetails: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit("open-details", $event.id, "typeResource"))),
                              onAction: _ctx.actionShowTR,
                              icon: "show"
                            }, null, 8, ["typeResources", "onNextPage", "onAction"])
                          ]))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.hideItems, (item, i) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: _normalizeClass(["pa-2", _ctx.isTablet ? (!_ctx.store.state.globals.details && _ctx.isLaptop ? "col-2" : "col-3") : "col-6"]),
                              key: i
                            }, [
                              _createVNode(_component_GridMap, {
                                item: item,
                                onNextPage: _ctx.nextPage,
                                onOpenDetails: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit("open-details", $event.id, "typeResource"))),
                                onAction: _ctx.actionShowTR,
                                icon: "show"
                              }, null, 8, ["item", "onNextPage", "onAction"])
                            ], 2))
                          }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}