
import {defineComponent, ref, onMounted, computed, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {useRouter} from "vue-router";
import {TypeResources} from "@/models";
import ListMap from "@/views/maps/components/typeResources/components/ListMap.vue";
import GridMap from "@/views/maps/components/typeResources/components/GridMap.vue";

export default defineComponent({
	emits: ["item-refresh-request", "open-details"],
	props: {
		detailsId: Number,
		isRefreshRequest: {
			type: Boolean,
			default: false
		},
		selectedTypeLink: Array
	},
	components: {
		ListMap,
		GridMap
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();
		const router = useRouter();

		const mapView = computed(() => store.state.globals.mapView);

		const typeResources = computed(() => store.state.dictionaries.typeResources as TypeResources.TypeResource[]);
		const showItems = computed(() => typeResources.value.filter(typeResource => !idsTR.value.includes(typeResource.id)))
		const hideItems = computed(() => typeResources.value.filter(typeResource => idsTR.value.includes(typeResource.id)))

		const loading = ref(true);
		const load = async () => {
			if(store.state.globals.enterpriseId) {
				loading.value = true;
				await store.dispatch('dictionaries/getTypeResources');
				loading.value = false;
				emit('item-refresh-request');

				if(store.state.globals.detailsTypeResource)
					emit("open-details", props.detailsId, 'typeResource')

				idsTR.value = idsTR.value.filter(ids => typeResources.value.find(typeResource => typeResource.id === ids))
				window.localStorage.setItem('idsTR', JSON.stringify(idsTR.value))
			} else {
				router.push({name: "change_enterprise"})
			}
		}

		onMounted(async () => await load());
		watch(() => props.isRefreshRequest, async () => {
			if(props.isRefreshRequest)
				await load()
		});

		const nextPage = (id: number) => router.push({name: 'maps', params: {idTypeRes: id}});

		const isTablet = ref(true);
		isTablet.value = window.innerWidth >= 600;
		window.addEventListener('resize', () => isTablet.value = window.innerWidth >= 600);

		const isLaptop = ref(true);
		isLaptop.value = window.innerWidth >= 1264;
		window.addEventListener('resize', () => isLaptop.value = window.innerWidth >= 1264);

		const isShowHideTR = ref(false);
		const idsTR = ref(window.localStorage.getItem('idsTR') ? JSON.parse(String(window.localStorage.getItem('idsTR'))) : []);
		const actionShowTR = (id: number) => {
			idsTR.value = idsTR.value.filter(ids => ids !== id);
			window.localStorage.setItem('idsTR', JSON.stringify(idsTR.value));

			if(idsTR.value.length === 0)
				isShowHideTR.value = false
		}
		const actionHideTR = (id: number) => {
			idsTR.value = [...idsTR.value, id];
			window.localStorage.setItem('idsTR', JSON.stringify(idsTR.value));
		}

		return {
			t, store,
			mapView, nextPage,
			loading, load,
			typeResources, showItems, hideItems,
			isTablet, isLaptop,
			idsTR, actionHideTR, actionShowTR, isShowHideTR
		}
	}
})
