import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/images/no-image.png'


const _hoisted_1 = { class: "maps-list-item pa-2" }
const _hoisted_2 = { class: "maps-list-item-img mb-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  src: _imports_0
}
const _hoisted_5 = { class: "maps-list-item-text text-body-2 text-center" }
const _hoisted_6 = { class: "maps-list-item-action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_tooltip = _resolveComponent("app-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('next-page', _ctx.item.id)))
    }, [
      _createElementVNode("span", _hoisted_2, [
        ((_ctx.item.avatar ?? "").indexOf("base64") > 0)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.item.avatar
            }, null, 8, _hoisted_3))
          : (_openBlock(), _createElementBlock("img", _hoisted_4))
      ]),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.item.name), 1)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_app_button, {
        class: "mt-2",
        block: "",
        small: "",
        outlined: "",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit("open-details", _ctx.item)), ["stop"])),
        style: {"text-transform":"capitalize"}
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("button.details")), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_app_tooltip, { placement: "bottom-end" }, {
        content: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t(`button.${_ctx.icon}`)) + " " + _toDisplayString(_ctx.t("dictionary.typeResource")), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_app_button, {
            small: "",
            icon: "",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit("action", _ctx.item.id)), ["stop"])),
            style: {"width":"28px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_icon, {
                icon: _ctx.icon,
                size: 16
              }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}