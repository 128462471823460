
import {defineComponent, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import {TypeResources} from "@/models";

export default defineComponent({
	emits: ['next-page', 'open-details', 'action'],
	props: {
		item: {
			type: Object as PropType<TypeResources.TypeResource>,
			required: true
		},
		icon: {
			type: String,
			default: 'hide'
		}
	},
	setup() {
		const { t } = useI18n();
		return { t }
	}
})
